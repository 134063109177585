import React from "react";
import { graphql, Link } from "gatsby"
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Paper, Grid, Button, Box } from "@material-ui/core";
import { SoldListingItem, timeAgo, LeaseLengthLabels, CreditScoreLabels, DownPaymentLabels, getExteriorColorIndex, getInteriorColorIndex } from "leasemojo-common";

import Layout from '../components/Layout';
import CarLogo from "../components/CarImage/CarLogo";
import TrimImage from "../components/CarImage/TrimImage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginTop: theme.spacing(2),
      },
      spacer: {
        width: theme.spacing(1),
        height: theme.spacing(1),
      },
      header: {
        textAlign: 'center',
        paddingBottom: theme.spacing(1),
      },
      photos: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 1),
        textAlign: 'center',
      },
      carPhoto: {
        display: 'inline-block',
        width: 450,
        height: 350,
        backgroundSize: 'cover',
        flex: '0 0 auto',
        margin: theme.spacing(1),
        '&:first-child': {
          margin: theme.spacing(1, 0),
        },
        '&:last-child': {
          margin: theme.spacing(1, 0),
        }
      }
    }
  ),
);


interface Props {
  data: {
    publishedSale: SoldListingItem;
  }
}

const SoldCar: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const exteriorColorIndex = getExteriorColorIndex(data.publishedSale.exteriorColor, data.publishedSale.trimData.exteriorColors);
  const interiorColorIndex = getInteriorColorIndex(data.publishedSale.interiorColor, data.publishedSale.trimData.interiorColors);



  const title = `$${data.publishedSale.monthlyPayment} a month - ${data.publishedSale.carData?.name} ${data.publishedSale.modelData?.name} - ${data.publishedSale.trimData.name}`;
  let ogImage;

  if (data.publishedSale.photos.length === 0) {
    const colorIndex = getExteriorColorIndex(data.publishedSale.exteriorColor, data.publishedSale.trimData.exteriorColors);
    if (data.publishedSale.trimData.exteriorColors[ colorIndex ].images.lg) {
      ogImage = process.env.CDN_BASE_URL_CARS + '/' + data.publishedSale.trimData.exteriorColors[ colorIndex ].images.lg;
    }
    else {
      ogImage = process.env.CDN_BASE_URL_CARS + '/' + data.publishedSale.modelData?.placeholderImage;
    }
  }
  else {
    ogImage = process.env.CDN_BASE_URL + '/' + data.publishedSale.photos[ 0 ].src
  }



  return (
    <Layout contentClassName={classes.root} title={title} ogImage={ogImage}>

      <div className={classes.header}>
        <CarLogo car={data.publishedSale.carData} />
        <Typography variant="h6">{data.publishedSale.modelData?.modelYear} {data.publishedSale.carData?.name} {data.publishedSale.modelData?.name}</Typography>
        <div className={classes.spacer} />
        <Typography variant="h6" align="center">
          Only ${data.publishedSale.monthlyPayment} a month
        </Typography>
        <div className={classes.spacer} />
        <Typography variant="body1">Leased {timeAgo(data.publishedSale.createTime as number)}</Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.photos}>
        {
          data.publishedSale.photos.length === 0 ? (
            <TrimImage trim={data.publishedSale.trimData} color={data.publishedSale.exteriorColor} size={450} />
          ) : data.publishedSale.photos.map(photo => {
            const styles: any = {
              backgroundImage: `url(${process.env.CDN_BASE_URL + '/' + photo.src})`
            };
            if (typeof window !== 'undefined') {
              const width = window.innerWidth <= 500 ? 300 : 450;
              const height = width / 1.3;
              styles.width = width;
              styles.height = height;
            }

            return <Paper key={photo.src} className={classes.carPhoto} style={styles} />
          })
        }
      </div>

      <Container maxWidth="xs">
        <Typography variant="h6" align="center">{data.publishedSale.trimData.name}</Typography>

        <div className={classes.spacer} />
        <div className={classes.spacer} />

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            component={Link}
            to={`/build/${data.publishedSale.carData?.slug}/${data.publishedSale.modelData?.slug}`}
          >
            Lease Similar Car
          </Button>
        </Box>

        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <div className={classes.spacer} />
        <div className={classes.spacer} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Exterior</Typography>
            <Typography variant="body1">{data.publishedSale.trimData.exteriorColors[ exteriorColorIndex ].name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Interior</Typography>
            <Typography variant="body1">{data.publishedSale.trimData.interiorColors[ interiorColorIndex ].name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Length</Typography>
            <Typography variant="body1">{LeaseLengthLabels.get(data.publishedSale.leaseLength)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Credit Score</Typography>
            <Typography variant="body1">{CreditScoreLabels.get(data.publishedSale.creditScore)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Down</Typography>
            <Typography variant="body1">{DownPaymentLabels.get(data.publishedSale.downPayment)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Monthly</Typography>
            <Typography variant="body1">${data.publishedSale.monthlyPayment}</Typography>
          </Grid>

        </Grid>

      </Container>

      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />
      <div className={classes.spacer} />

    </Layout>
  );
};

export default SoldCar;

export const query = graphql`
  query($id: String!) {
    publishedSale(id: {eq: $id}) {
      id
      createTime
      carData {
        name
        logoUrl
        slug
      }
      modelData {
        id
        name
        bodyStyles
        imageUrl
        modelYear
        placeholderImage
        slug
      }
      trimData {
        id
        name
        bodyStyle
        msrp
        slug
        exteriorColors {
          images {
            lg
            md
          }
          msrp
          name
          id
        }
        interiorColors {
          msrp
          name
          id
        }
        modelYear
      }
      photos {
        src
        thumb
        path
      }
      leaseLength
      downPayment
      creditScore
      monthlyPayment
      exteriorColor
      interiorColor
      mileage
    }
  }
`
